.contact-us-page {
  padding-bottom: 0;
  color: $white;
  @media (min-width: $min-width) {
  }
  &_hero {
    display: flex;
    flex-direction: column-reverse;
    text-align: center;
    padding-bottom: 80px;
    align-items: center;
    &-left {
    }
    &-right {
    }
    @media (min-width: $min-width) {
      padding-bottom: 80px;
      height: calc(100vh - 108px);
      text-align: left;
      flex-direction: row;
      justify-content: space-between;
      &-left {
        width: 50%;
      }
    }
  }
  &-location {
    padding-bottom: 125px;
    position: relative;
    .heading-2 {
      margin-bottom: 3rem;
    }
    &-list-mobile {
      display: flex;
      @media (min-width: $min-width) {
        display: none;
      }
    }
    &-list-desktop {
      padding-left: 1rem;
      display: none;
      @media (min-width: $min-width) {
        display: flex;
        z-index: 2;
        position: relative;
      }
    }
    .cert-card{
      height: 100%;
      padding: 10px;
      border-radius: 10px;
      background: transparent
        linear-gradient(180deg, $aqua-deep 0%, $bluish-cyan 100%) 0% 0%
        no-repeat padding-box;
      
      img{
        width: 100%;
        height: 100%;
        opacity: .5;
        &:hover{
          opacity: 1;
        }
      }
    }
    .location-card {
      // height: 250px;
      height: 100%;
      background: transparent
        linear-gradient(180deg, $aqua-deep 0%, $bluish-cyan 100%) 0% 0%
        no-repeat padding-box;
        border-radius: 10px;
        margin-right: 16px;
        margin-bottom: 20px;
        padding: 10px;
        // padding-bottom: 30px;
      text-align: left;
      &-content {
        padding: 30px;
        &_country {
          font-family: "IBM-Regular";
          font-size: 16px;
        }
        &_city {
          font-family: "IBM-Bold";
          font-size: 20px;
          margin-bottom: 16px;
        }
        &_address {
          font-family: "IBM-Regular";
          font-size: 16px;
          opacity: 0.7;
        }
      }
      @media (min-width: $min-width) {
        height: auto;
      }
    }
  }
  &-reach {
    padding-top: 60px;
    &-box {
      border-radius: 20px;
      background-color: $shade-black;
      position: relative;
      &-content {
        padding: 32px;
        display: flex;
        flex-direction: column;
        margin-bottom: 150px;
        &_left {
          flex: 4;
          padding-right: 60px;
          .social-media-images {
            width: 150px;
            display: flex;
            justify-content: space-around;
            padding-top: 30px;
            img {
              width: 25px;
            }
          }
        }
        &_right {
          flex: 5;
        }
      }
    }
    @media (min-width: $min-width) {
      &-box {
        &-content {
          flex-direction: row;
          padding: 60px;
          align-items: center;
          &-left {
            padding-top: 50px;
          }
        }
      }
    }
  }
  .apply-now-form {
    padding-top: 60px;

    @media (min-width: $min-width) {
      padding-left: 30px;
    }
  }

  .apply-now-form-input-input {
    background-color: $shade-black;
    border: 1px solid $light-grey;
    margin: 10px 0;
    width: 100%;
    border-radius: 0.75rem;
    padding: 1rem;
    font-size: 16px;
  }

  input {
    height: 5rem;
  }
  textarea {
    height: 120px;
  }
  .apply-now-form-controls {
    padding-top: 20px;
  }
}
.blue-wave {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  @media (min-width: $min-width) {
    height: 85%;
  }
}
.grid {
  position: absolute;
  right: -40px;
  width: 45%;
  top: 50px;
}
