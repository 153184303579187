.career-page {
  color: $white;
  margin: 30px;
  text-align: center;
  padding: 0 50px;
  @media (min-width: $min-width) {
    margin: 0 80px;
    
    text-align: left;
    // width: 1000px;
    width: unset;
  }
  @media (min-width: $big-screen) {
    margin-left: 140px;
    text-align: left;
    width: auto;
  }
  .section-1 {
    display: flex;
    margin-bottom: 100px;
    flex-direction: column-reverse;
    text-align: center;
    @media (min-width: $min-width) {
      margin: 0;
      align-items: center;
    }
    @media (min-width: $big-screen) {
      text-align: left;
      height: 70vh;
      flex-direction: row;
    }
      
  }
  &_left-content {
    .career-title {
      font-size: 3rem;
      width: 350px;
    }
    .career-text {
      color: $light-grey;
      font-size: 1.6rem;
      line-height: 2.5rem;
      @media (min-width: $min-width) {
        line-height: 3rem;

      }
      @media (min-width: $big-screen) {
        font-size: 2rem;
      }
    }
    @media (min-width: $big-screen) {
      width: 50%;
    }
    @media (min-width: $min-width) {
      display: flex;
      flex-direction: column;
      // justify-content: center;
    }
  }
  &_right-content {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (min-width: $min-width) {
      width: 50%;
      margin: 0px !important;
    }
    .image-container {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      .foreground-image {
        position: absolute;
        height: 80%;
      }
      .background-image {
        width: 100%;
      }
      @media (min-width: $min-width) {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        .background-image {
          width: 65%;
          height: fit-content;
        }
        .foreground-image {
          position: absolute;
          height: 65%;
        }
      }
    }
  }
  .section-2 {
    position: inherit;
    background-image: url("../images/Repeat\ Grid\ 4.svg");
    background-repeat: no-repeat;
    background-position-x: 1000px;
    background-position-y: 310px;
    text-align: left;
    @media (min-width: $min-width) {
      padding-bottom: 150px;
    }

    .career-opening-title {
      font-size: 60px;
      width: 350px;
      margin-bottom: 50px;
    }
    .accordion-div {
      height: 900px;
      @media (min-width: $min-width) {
        margin-bottom: 100px;
        height: 500px;
        text-align: left;
      }
      .accordion-title {
        height: 35px;
        font-size: 1.5rem;
        padding: 24px 12px;
      }
      .accordion-content {
        opacity: 0.7;
      }
    }
  }
  .section-3 {
    // background-image: url("../images/asdasdasd.png");
    background-size: contain;
    @media (min-width: $min-width) {
      margin-left: -155px;
    }
    .about {
      padding: 0;
      margin: 8rem 0;
    }
  }
}
.apply-now-container {
  background-color: $shade-black;
  margin-bottom: 20px;
  input {
    background-color: $shade-black;
    border: 1px solid $white;
    margin: 10px 0;
    width: 100%;
    height: 25px;
  }
  textarea {
    background-color: $shade-black;
    border: 1px solid $white;
    margin: 10px 0;
    width: 100%;
    height: 100px;
  }
  @media (min-width: $min-width) {
    border-radius: 20px;
    padding: 30px;
    width: 400px;
    z-index: 1;
    input {
      margin: 10px 0;
      width: 100%;
      height: 25px;
    }
    textarea {
      margin: 10px 0;
      width: 100%;
      height: 100px;
    }
  }
  @media (min-width: $min-width) {
    width: 500px;
  }
}
