.nav-menu-wrapper {
  padding: 10px 0 0;
  height: 100vh;
  @media (min-width: $big-screen) {
    padding: 30px 0 0;
  }
  .phonetext{
    font-size: 1.6rem !important;
    margin: 0;
    @media (min-width: $big-screen) {
      font-size: 1.4rem !important;
    }
  }
  .nav-menu {
    margin: 0 15px;
    .nav-header {
      text-align: left;
      padding-bottom: 20px;
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
      @media (min-width: $big-screen) {
        flex-direction: row;
      }
      .dummyDiv{
        display: none;
        @media (min-width: $big-screen) {
          display: block;
        }
      }
      @media (min-width: $big-screen) {
        padding-bottom: 60px;
        // text-align: center;
      }
      .closeIcon{
        position: unset;
        text-align: left !important;
      }
      img {
        // position: absolute;
        top: 64px;
        right: 5%;
        z-index: 8;
        @media (min-width: $big-screen) {
          right: unset;
          left: 0;
        }
      }
      .link-text {
        @media (min-width: $big-screen) {
          left: unset;
          position: unset;
        }

        @media (min-width: $min-width) {
          left: unset;
        }
      }
    }
  }
  .nav-content {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    @media (min-width: $big-screen) {
      flex-direction: row;
    }
    @media (min-width: $min-width) {
      align-items: center;
    }
    .menu-left {
      .menu-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-left: 5%;
        @media (min-width: $big-screen) {
          padding-left: 0;
        }
        .verticle-line {
          width: 1px;
          background-color: $secondary;
          position: absolute;
          height: 100%;
          left: 40%;
          z-index: 9;
          display: none;
          @media (min-width: $big-screen) {
            display: block;
          }
        }
        .link {
          display: flex;
          padding-bottom: 20px;
          &:last-child {
            padding-bottom: 0;
          }
          .nav-link-text {
            text-transform: uppercase;
            color: $white;
            font-size: 2rem;
            line-height: 2.4rem;
            font-family: "IBM-Regular";

            @media (min-width: $min-width) {
              font-size: 2.6rem;
              line-height: 3rem;
            }
            @media (min-width: $big-screen) {
              font-size: 3rem;
              line-height: 3.4rem;
            }
            img {
              position: relative;
              left: 8px;
              transition: 0.25s;
            }
            .selected {
              transition: 0.25s;
              transform: rotate(-90deg);
            }
            .not-selected {
              transform: rotate(90deg);
            }
          }
          .child-list {
            color: $white;
            position: absolute;
            font-family: "IBM-Bold";
            display: none;
            left: 150px;
            top: 0;
            margin-left: 20%;
            flex-direction: column;
            .list-element {
              display: block;
              padding: 10px 0;
              color: $star-dust;
              font-size: 1.4rem;
              line-height: 1.8rem;
              @media (min-width: $min-width) {
                font-size: 2.4rem;
                line-height: 2.8rem;
              }
              @media (min-width: $big-screen) {
                font-size: 2rem;
                line-height: 2.4rem;
              }
              &:hover {
                color: $secondary;
              }
            }
          }
        }
      }

      .mobile-social {
        .social-media {
          display: block;
          text-align: center;
          h5 {
            font-family: "IBM-Bold";
            font-size: 2rem;
            opacity: 0.5;
            color: $white;
            margin-bottom: 10px;
          }
          .social-media-images {
            display: flex;
            justify-content: space-around;
            img {
              height: 30px;
              width: 30px;
            }
          }
          @media (min-width: $big-screen) {
            display: none;
          }
        }
      }
    }
    .nav-menu-content-right {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      margin-bottom: 30px;
      @media (min-width: $big-screen) {
        flex-direction: column;
        margin-bottom: 0;
      }
      .nav-menu-brain-image-wrapper {
        width: 90%;
        height: 100%;
        margin-top: 16px;
        img {
          object-fit: cover;
          width: 100%;
          height: inherit;
        }
        @media (min-width: 425px) and (max-width: 768px) {
          width: 75%;
          margin-top: 0;
          text-align: center;
        }
        @media (min-width: $min-width) {
          width: 72%;
          margin-top: 0;
          text-align: center;
        }
        @media (min-width: $big-screen) {
          width: 100%;
          margin-top: 0;
          text-align: center;
          img {
            width: 100%;
          }
        }
      }
      .nav-menu-content-right__footer {
        display: flex;
        justify-content: space-around;
        width: 90%;
        p {
          font-family: "IBM-Bold";
          font-size: 2rem;
          opacity: 0.5;
          color: $white;
          margin-bottom: 10px;
        }
        .text {
          font-family: "IBM-Regular";
          font-size: 1.6rem;
          line-height: 2rem;
          color: $white;
          margin-left: 10px;
        }
        @media (min-width: $min-width) {
          p {
            font-size: 2rem;
            line-height: 2.4rem;
          }
          .text {
            font-size: 1.6rem;
          }
        }
        @media (min-width: $big-screen) {
          p {
            font-size: 2rem;
            line-height: 2.4rem;
          }
          .text {
            font-size: 1.4rem;
            line-height: 1.8rem;
            margin-left: 0;
          }
        }
        .desktop-social {
          display: none;
          @media (min-width: $min-width) {
            display: none;
          }
          @media (min-width: $big-screen) {
            display: block;
            .social-media {
              h5 {
                font-family: "IBM-Bold";
                font-size: 2rem;
                line-height: 2.4rem;
                opacity: 0.5;
                color: $white;
                margin: 20px 0 10px;
              }
              .social-media-images {
                font-size: initial;
                img {
                  height: 18px;
                  margin-right: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
}
