@import './variables.scss';
.job-description {
  color: $white;
  font-style: "IBM-Regular";
  padding: 0 40px;
  &_heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    button {
      margin: 20px 0;
      height: 40px;
      width: 200px;
    }
  }
  @media (min-width: $min-width) {
    // padding: 0 100px;
    &_heading {
      flex-direction: row;
      button {
        width: auto;
      }
    }
  }
  &_title {
    font-size: 48px;
    line-height: 70px;
  }
  &-title {
    font-size: 2.5rem;
    line-height: 70px;
  }
  &-content {
    font-size: 1.6rem !important;
    color: $light-grey;
    line-height: 2.5rem !important;
    opacity: 0.7;
  }
  &_apply-now-section {
    margin-top: 100px;
    z-index: 2;
    @media (min-width: $min-width) {
      // background-image: url("../images/asdasdasd.png");
      margin-left: -155px;
      background-size: contain;
      margin-top: 195px;
    }
    img {
      z-index: -1;
      position: absolute;
      width: 125%;
      left: -44px;
      top: 52px;
      @media (min-width: $min-width) {
        // z-index: 0;
        width: 109%;
        left: 0;
        top: -80px;
        height: 100%;
      }

    }
    @media (min-width: $min-width) {
      display: flex;
      justify-content: end;
    }
  }
  .careerform-container{
    .subhead{
      font-size: 1.6rem;
    }
    border-radius: 10px;
    margin-bottom: 12rem;
    padding: 1rem 3rem 2rem 3rem; 
    z-index: 3;
    min-height: 30rem;
    background-color: $shade-black;
    width: 100%;
    @media (min-width: $min-width) {
      width: 80%;
    }
    @media (min-width: $big-screen) {
      width: 40%;
    }
    h3{
      font-family: 'IBM-Light';
      margin: 0;
      span{
        color: $secondary;
      }
    }
    form{
      
      div{
        display: flex;
        justify-content: space-between;
        margin-bottom: 1.5rem;
        span{
          width: 45%;
          display: flex;
          flex-direction: column;
          label{
            font-size: 1.6rem;
            margin: 1rem 0;
          }
          .input{
            background-color: $shade-black;
            border: 2px solid #5F5F5F;
            border-radius: 10px;
            font-size: 1.6rem;
            padding: 0.7rem 1.3rem;
            height: 3.5rem;
            &:focus-visible{
              outline: none;
            }
          } 
          input[type=number]{
            -moz-appearance:textfield;
            // -webkit-appearance: none;
          }
          .file-con{
            margin: 0;
            position: relative;
            font-size: 1.3rem;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            .file{
              position: absolute;
              display: none;
            }
          } 
        }
      }
      .submit{
        display: flex;
        justify-content: center;
        margin-top: 4rem;
        .submitBtn{
          width: 14rem;
          height: 3.5rem;
        }
      }
    }
    // position: relative;
    // margin-bottom: 18rem;
    
    // .apply-now-form{
    //   .apply-now-form-inputs{
    //     background-color: $shade-black;
    //     border: 1px solid $light-grey;
    //     margin: 10px 0;
    //     width: 100%;
    //     border-radius: 0.75rem;
    //     padding: 1rem;
    //     font-size: 16px;
    //   }
    // }
  }
}

ul{
  list-style: none;
  li{
    &::before{
      content: "\2022";
      color: $primary;
      font-weight: bold;
      display: inline-block; 
      width: 1em;
      margin-left: -1em;
    }
  }
}
