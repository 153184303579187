.footer {
  // width: 100vw;
  position: relative;
  z-index: 2;
  padding: 40px;
  color: $white;
  background: $shade-black;
  @media (min-width: $min-width) {
    padding: 25px 140px;
  }
  @media (min-width: $big-screen) {
    // padding: 25px 300px;
  }
  .section-4{
    margin-top: 2rem;
    text-align: center;
    font-size: 14px;
    opacity: 50%;
  }
  .sec{
    h3{
      color: $secondary;
      
    }
  }
}
.footer-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2rem;
  @media (min-width: $big-screen) {
    column-gap: 5rem;
    grid-template-columns: 1fr 2fr 1fr;
  }
  // text-align: center;

  .navLogo{
    margin-left: 0 !important;
    margin-bottom: 1rem;
  }
  width: 100%;
  .sec {
    @media (min-height: $big-screen) {
      padding-left: 6rem;
    }
    h3 {
      font-size: 16px;
      @media (min-width: $big-screen) {
        // color: $white;
        opacity: 50%;
        font-size: 18px;
      }
    }
    p {
      color: $white;
      font-size: 14px;
      @media (min-width: $big-screen) {
        font-size: 16px;
      }
    }
  }
  .phone {
    
    .phWrap{
      display: flex;
      flex-direction: column;
      row-gap: 1.5rem;
      column-gap: 3rem;
      @media (min-width: $big-screen) {
        flex-direction: row;
      }
    }
    p {
      font-size: 14px;
      margin: 0;
      @media (min-width: $big-screen) {
        font-size: 16px;
      }
    }
  }
  .navigation {
    // grid-column-start: 1;
    // grid-row-start: 1;

    font-family: "IBM-Regular";
    // gap: 10px;
    // padding-top: 10px;
    @media (min-width: $big-screen) {
      // grid-column-start: 1;
      // grid-row-start: 2;
      // gap: unset;
      // padding-top: unset;
    }
    .links-wrapper {
      display: flex;
      flex-direction: column;
      // text-align: start;
      font-size: 14px;
      gap: 10px;
      padding-top: 10px;
      @media (min-width: $big-screen) {
        font-size: 16px;
        // gap: 1rem;
        gap: 1rem;
        padding-top: unset;
      }
      a {
        color: $white;
      }
    }
  }
  .address{
    grid-column-start: 1;
    grid-column-end: 3;
    @media (min-width: $big-screen) {
      grid-column-start: unset;
      grid-column-end: unset;
    }
    p{
      line-height: 3rem;
    }
  }
  .solutions {
    // grid-column-start: 1;
    // grid-row-start: 1;

    font-family: "IBM-Regular";
    // gap: 10px;
    // padding-top: 10px;
    grid-column-start: 2;
    grid-row-start: 1;
    
    @media (min-width: $big-screen) {
      grid-column-start: unset;
      grid-row-start: unset;
      // grid-column-start: 1;
      // grid-row-start: 2;
      // gap: unset;
      // padding-top: unset;
    }
    .links-wrapper {
      display: flex;
      flex-direction: column;
      // text-align: start;
      font-size: 14px;
      gap: 10px;
      padding-top: 10px;
      @media (min-width: $big-screen) {
        font-size: 16px;
        gap: 1rem;
        // gap: unset;
        padding-top: unset;
      }
      a {
        color: $white;
      }
    }
  }
  .contWrap{
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 3;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2rem;
    @media (min-width: $big-screen) {
      grid-template-columns: 1fr;
      grid-column-start: unset;
      grid-column-end: unset;
      grid-row-start: unset;
      flex-direction: column;
    }
  }
  .Ft-title {
    grid-column-start: 1;
    grid-column-end: 3;
    // text-align: center;
    display: flex;
    justify-content: center;
    margin-top: 18px;
    text-align: center;


    @media (min-width: $big-screen) {
      justify-content: start;
      grid-column-start: unset;
      grid-column-end: unset;

      text-align: left;
    }
    .astra {
      color: $secondary;
    }
    .getQ {
      font-size: 14px;
      margin-top: 1rem;
      margin-bottom: 1rem;
      opacity: 50%;
      @media (min-width: $big-screen) {
        // font-size: 18px;
        margin-top: 2rem;
      }
    }
    img {
      margin-left: 1rem;
    }
  }
  .follow {
    // grid-row-start: 1;
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 4;
    @media (min-width: $big-screen) {
      grid-column-start: unset;
      grid-column-end: unset;
      grid-row-start: unset;
    }
    .links-wrapper {
      a {
        color: $white;
      }
      .footer-text {
        // display: flex;
        // flex-direction: column;
        // gap: 12px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        font-size: 14px;
        column-gap: 2rem;
        row-gap: 1rem;
        @media (min-width: $big-screen) {
          row-gap: unset;
          grid-template-columns: 1fr;
          font-size: 16px;
          // flex-direction: row;
        }
      }
    }
    // @media (max-width: $big-screen){
    //   grid-row-start: 1;
    //   flex-direction: column;
    // }
    @media (min-width: $big-screen) {
    }
  }
}
