body {
  margin: 0;
}

// .homepage-header {
//   padding-right: 40px;
//   padding-left: 40px;
//   align-items: center;

//   img {
//     cursor: pointer;
//     width: 25px;
//   }
//   .social-media {
//     h5 {
//       display: none;
//     }
//   }

//   .header-title {
//     text-align: center;
//     font-family: "IBM-Bold";
//     font-size: 24px;
//   }
// }
.about-us {
  padding-top: 26px;
  &-hero {
    margin-bottom: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    &-img {
      width: 100%;
      img {
        width: 100%;
      }
    }
    @media (min-width: $min-width) {
      flex-direction: row;
      &-img {
        width: 50%;
        img {
          width: 80%;
        }
      }
    }
    &-text {
      display: flex;
      flex-direction: column;
      align-items: center;
      // .eyebrow {
      //   margin-bottom: 0;
      // }
      .heading-2 {
        text-align: center;
        padding-bottom: 25px;
      }
      &-texts {
        text-align: center;
        color: $light-grey;
        font-family: "IBM-Regular";
        font-size: 14px;
        line-height: 18px;
      }
      @media (min-width: $min-width) {
        width: 50%;
        align-items: flex-start;
        .heading-2 {
          text-align: left;
          padding-bottom: 40px;
        }
        &-texts {
          text-align: left;
        }
      }
    }
  }
  &-team {
    z-index: 2;
    // padding: 0 40px 0;
    .grid-4 {
      overflow: hidden;
      position: relative;
      &-desktop {
        display: none;
      }
      &-mobile {
        display: block;
      }
      &-bg-img {
        position: absolute;
        top: 45.5%;
        object-fit: cover;
        width: 70%;
        height: 500px;
        right: -51%;
        opacity: 0.5;
        z-index: 0;
        display: none;
        @media (min-width: $min-width) {
          display: block;
        }
      }
    }
    // margin: 180px 0 60px;
    @media (min-width: $min-width) {
      margin: 180px 0 60px;
    }
    .team {
      display: flex;
      flex-direction: column;
      position: relative;
      z-index: 2;
      padding-bottom: 80px;
      flex-wrap: wrap;
      @media (min-width: $min-width) {
        flex-direction: row;
      }
      &-card {
        margin: 20px 8px;
        border-radius: 10px;
        background-color: $shade-black;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;
        &-header {
          padding-top: 30px;
          overflow: hidden;
          display: flex;
          justify-content: center;
          height: 100px;
          background: radial-gradient(circle at 50% 50%, $primary, $secondary);
          border-radius: 50%;
          width: 30%;
          margin: 30px auto 40px;
          opacity: 0.5;
          filter: blur(30px);
          backdrop-filter: blur(50px) brightness(-40%) opacity(9%) saturate(10%);
        }
        &-img {
          position: absolute;
          z-index: 2;
          top: 0px;
          padding: 30px 40px;
          width: 200px;
          border-radius: 50%;
          @media (max-width: 375px) {
            height: 30%;
          }
        }
        &-body {
          text-align: center;
          background-color: $shade-black;
          padding: 30px;
          border-radius: 0 0 10px 10px;
          position: relative;
          @media (max-width: 375px) {
            padding: 15px;
          }
          .co-founder-card-text{
            font-size: 1.6rem;
            // @media (min-width: $min-width) {
            //   font-size: 1.3rem;
            // }
          }
          h6 {
            color: white;
            font-size: 24px;
            line-height: 28px;
            font-family: "IBM-Regular";
            margin-bottom: 15px;
            margin-top: 0;
          }
          .pos {
            font-size: 18px !important;
            line-height: 22px;
            font-family: "IBM-Regular";
            color: $light-grey;
          }

          .texts {
            font-size: 16px !important;
            line-height: 20px;
            font-family: "IBM-Regular";
            color: $light-grey;
          }
          .social-media-images {
            display: flex;
            justify-content: space-around;
            margin: 0 60px;
            padding: 8px;
            img {
              width: 24px;
              padding-right: 5px;
            }
          }
        }
      }
      x {
        flex-direction: row;
        &-card {
          margin: 12px;
          .social-media-images {
            justify-content: space-around;
          }
        }
      }
    }
  }
  &-section {
    .about-box {
      min-height: 400px;
      height: fit-content;
      // backdrop-filter: blur(10px) opacity(1) contrast(0.7);
      .about-content {
        // padding: 30px;
      }
      @media (min-width: $min-width) {
        padding-bottom: 60px;
      }
    }
    .about {
      // padding: 20px 40px;
      margin: 10rem auto;
    }
    .blue-wave {
      overflow: hidden;
      width: 100%;
      position: relative;
      height: 100%;
      &-desktop {
        display: none;
      }
      &-mob {
        display: block;
      }
      img {
        width: auto;
        position: absolute;
        height: inherit;
        // object-fit: cover;
      }
      .waweImg{
        width: 193%;
        position: absolute;
        right: -60px;
        top: 107px;
        height: 50%;
        @media (min-width:$min-width) {
          
        }
        @media (min-width: $min-width) {
          width: 100%;
          position: absolute;
          right: 0;
          top: -80px;
          height: 130%;
        
        }
      }
    }
  }
}
.about-us-page {
  .about-us {
    background-image: none;
  }
}
// @media (min-width: 1301px) {
//   .homepage-header {
//     padding-right: 140px;
//     padding-left: 140px;
//     align-items: center;

//     img {
//       cursor: pointer;
//       width: 25px;
//     }
//     .social-media {
//       h5 {
//         display: none;
//       }
//     }

//     .header-title {
//       text-align: center;
//       font-family: "IBM-Bold";
//       font-size: 24px;
//     }
//   }
//   .about-us {
//     &-hero {
//       display: flex;
//       flex-direction: row;
//       padding: 0px 140px;
//       justify-content: space-between;
//       &-img {
//         margin-right: 8%;
//         flex: 40%;
//       }
//       &-text {
//         flex: 50%;
//         align-items: flex-start;
//         .heading-2 {
//           text-align: left;
//         }
//         &-texts {
//           text-align: left;
//           font-size: 18px;
//           line-height: 32px;
//           color: $light-grey;
//         }
//       }
//     }
//     &-team {
//       padding-left: 140px;
//       padding-right: 140px;
//       margin: 180px 0;
//       z-index: 2;
//       .grid-4 {
//         overflow: hidden;
//         position: relative;
//         &-desktop {
//           display: block;
//         }
//         &-mobile {
//           display: none;
//         }
//         &-bg-img {
//           position: absolute;
//           object-fit: cover;
//           width: 32%;
//           right: -2.5%;
//           opacity: 0.5;
//           z-index: 0;
//           bottom: 10%;
//           top: unset;
//         }
//       }
//       .team {
//         display: flex;
//         flex-direction: row;
//         justify-content: space-between;
//         position: relative;
//         z-index: 2;
//         padding-bottom: 80px;
//         &-card {
//           margin: 10px;
//           border-radius: 10px;
//           background-color: $shade-black;
//           position: relative;
//           display: flex;
//           flex-direction: column;
//           align-items: center;
//           &-header {
//             padding-top: 30px;
//             overflow: hidden;
//             display: flex;
//             justify-content: center;
//             height: 170px;
//             background: radial-gradient(
//               circle at 50% 50%,
//               $primary,
//               $secondary
//             );
//             border-radius: 50%;
//             width: 30%;
//             margin: 30px auto 0;
//             opacity: 0.5;
//             filter: blur(50px);
//             backdrop-filter: blur(50px) brightness(-40%) opacity(9%)
//               saturate(10%);
//           }
//           &-img {
//             position: absolute;
//             z-index: 2;
//             top: 0px;
//             padding: 30px 40px;
//           }
//           &-body {
//             text-align: center;
//             background-color: $shade-black;
//             padding: 30px;
//             border-radius: 0 0 10px 10px;
//             h6 {
//               font-size: 24px;
//               line-height: 28px;
//               font-family: "IBM-Regular";
//               margin-bottom: 10px;
//             }
//             .pos {
//               font-size: 18px !important;
//               line-height: 22px;
//               font-family: "IBM-Regular";
//               color: $light-grey;
//             }

//             .texts {
//               font-size: 16px !important;
//               line-height: 20px;
//               font-family: "IBM-Regular";
//               color: $light-grey;
//             }
//             .social-media-images {
//               display: flex;
//               justify-content: space-between;
//               margin: 0 40px;
//               img {
//                 width: 24px;
//               }
//             }
//           }
//         }
//       }
//     }
//     &-section {
//       padding-bottom: 100px;
//       .about-box {
//         min-height: 600px;
//         backdrop-filter: blur(10px) opacity(1) contrast(0.7);
//       }
//       .about {
//         padding-left: 140px;
//         padding-right: 140px;
//       }
//       .blue-wave {
//         overflow: hidden;
//         width: 100%;
//         position: relative;
//         min-height: 600px;
//         &-desktop {
//           display: block;
//         }
//         &-mob {
//           display: none;
//         }
//         img {
//           width: 100%;
//           position: absolute;
//           top: 0%;
//           height: 100%;
//         }
//       }
//     }
//   }
// }

//   $secondary 120.07deg,
//   $primary 179.52deg,
//   $secondary 241.65deg,
//   $primary 299.6deg,
//   $secondary 360deg
