.supported-by {
  text-align: center;
  margin-top: 50px;
  &-list {
    display: flex;
    justify-content: space-around;
    padding-top: 35px;
    padding-bottom: 60px;
    img {
      opacity: 0.75;
      height: 50px !important;
      position: static !important;
      object-fit: contain !important;
      filter: grayscale(100%);
      @media (min-width: $min-width) {
        height: 100px !important;
      }
    }
  }
}
