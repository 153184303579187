.class-1 {
  position: relative;
  height: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 6rem 0;
  .image-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
    height: 30vh;
    width: 30vh;
    max-height: 200px;
    max-width: 200px;
    filter: blur(65px);
    background: radial-gradient(circle at 100% 100%, #07e3b0, #05eefa);
    backdrop-filter: blur(50px) brightness(-40%) opacity(9%) saturate(10%);
    border-radius: 50%;
    @media (min-width: $big-screen) {
      max-height: 350px;
      max-width: 350px;
    }
  }
  .foreground-image {
    position: absolute;

    &.brain,
    &.tools,
    &.face-1,
    &.brain-2 {
      height: 40vh;
      max-height: 250px;
    }
    &.hand {
      height: 30vh;
    }
  }
  .foreground-image {
    height: 51vh;
  }
  @media (min-width: $big-screen) {
    display: flex;
    padding-top: 0;
    padding-bottom: 0;
    .image-container {
      width: 50vh;
      height: 50vh;
    }
    .foreground-image {
      &.brain,
      &.tools,
      &.face-1,
      &.brain-2 {
        height: 40vh;
        max-height: 400px;
      }
      &.hand {
        height: 45vh;
      }
    }
  }
  //   @media (min-width: $big-screen) {
  //     padding-top: 0;
  //     padding-bottom: 0;
  //     .image-container {
  //       width: 350px;
  //       height: 350px;
  //     }
  //     .foreground-image {
  //       &.brain,
  //       &.tools,
  //       &.face-1 {
  //         width: 400px;
  //         height: 400px;
  //       }
  //       &.brain-2 {
  //         width: 350px;
  //       }
  //       &.hand {
  //         height: 300px;
  //       }
  //     }
  //   }
}
.product-page {
  .foreground-image {
    position: absolute;
    top: 20px;
    @media (min-width: $big-screen) {
      top: unset;
    }
  }
}
