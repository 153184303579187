// .container {
//   padding: 0;
//   @media (min-width: $min-width) {
//     padding: 15px;
//   }l
// }
body {
  margin: 0;
}

.link-text {
  color: $white;
  text-decoration: none;
  text-align: center;
  font-family: "IBM-Bold";
  font-size: 32px;
  @media (min-width: $min-width) {
    position: relative;
    left: 50px;
  }
}

// .sticky {
//   position: fixed !important;
//   top: 0;
//   z-index: 4;
//   background-color: $black;
// }

.homepage-header {
  height: $header-height;
  @media (min-width: $big-screen) {
    // padding-right: 100px;
    // padding-left: 100px;
  }

  color: $white;

  img {
    cursor: pointer;
    width: 25px;
    margin-top: 20px;
    margin-left: 20px;
  }

  .social-media {
    h5 {
      display: none;
    }
  }
}

.header-title {
  text-align: center;
  font-family: "IBM-Bold";
  font-size: 32px;

  @media (min-width: $min-width) {
    position: relative;
    left: 50px;
  }
}

.controls {
  display: flex;
  // margin: auto;
  align-items: center;
  margin-bottom: 15px;
  justify-content: center;

  @media (min-width: $big-screen) {
  }
}

.previous-icon {
  height: 33px;
  width: 33px;
}

.horizontal-bar {
  height: 4px;
  width: 70px;
  background-color: $carbon-grey;
  border-radius: 30px;
  margin: 0 20px;
  overflow: hidden;
}

.progress {
  background-color: $secondary;
  height: 4px;
  width: 0px;
  border-radius: 30px;
  animation: progressBar 10s infinite linear;
}

@keyframes progressBar {
  0% {
    width: 0%;
  }

  50% {
    width: 100%;
    transform: translateX(0px);
  }

  100% {
    width: 100%;
    transform: translateX(125px);
  }
}

.next-icon {
  height: 33px;
  width: 33px;
}

.homepage {
  width: 100%;
  background-color: $black;
  color: $white;
  flex-direction: row-reverse;

  &-header {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 0 2.5rem;

    @media (min-width: $min-width) {
      margin: 10px 0px;
      margin-bottom: 35px;
      flex-direction: row;
    }

    @media (min-width: $big-screen) {
      flex-direction: row;
      padding: 0;
    }

    .social-media img:not(:last-child) {
      margin-right: 40px;
    }

    .social-media {
      display: none;
      align-items: center;

      @media (min-width: $min-width) {
        display: unset;
      }
    }
  }
}

.hero-component {
  padding: 0 45px;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;

  @media (min-width: 1024px) {
    padding: 0;
    height: calc(100vh - $header-height);
  }
}

.hero {
  display: flex;
  flex-direction: column-reverse;
  text-align: center;

  @media (min-width: $min-width) {
    align-items: center;
  }

  @media (min-width: $big-screen) {
    padding-top: 40px;
    flex-direction: row;
    text-align: left;
    padding-bottom: 45px;
    align-items: center;
  }
  .class-1 {
    padding: 0 !important;
    margin: 2rem 0 !important;
    .image-container {
      height: 20vh;
      width: 20vh;
      filter: blur(50px);
      @media (min-width: $big-screen) {
        height: 25vh;
        width: 25vh;
        filter: blur(65px);
      }
    }
  }
}

.hero-image {
  position: relative;
  display: flex !important;
  align-items: center;
  justify-content: center;

  .background-image {
    width: 75%;
  }

  .foreground-image {
    position: absolute;
  }

  .brain {
    height: 50vh !important;
  }

  @media (min-width: $min-width) {
    // width: 50%;

    // .background-image {
    //   width: 65%;
    // }
    // .foreground-image {
    //   width: 40%;
    // }
  }
}

// .hero-desktop {
//   display: none;
// }
// .hero-mobile {
//   display: unset;
// }
.homeContainer {
  overflow: hidden;
}

@media (min-width: $big-screen) {
  .homeContainer {
    overflow: unset;
  }
}

.hero-text-wrapper {
  width: 100%;

  .hero-text {
    p {
      color: $white;
      opacity: 0.7;
    }

    .text {
      height: 85px;

      @media (min-width: 1366px) {
        height: 150px;
        // font-size: 16px !important;
      }
    }
  }

  // @media (min-width: $min-width) {
  //   display: flex;
  //   flex-direction: column;
  //   @media (min-width: 1024px) {
  //     height: calc(100vh - 300px);
  //   }
  // }
  @media (min-width: $big-screen) {
    justify-content: center;
    width: 50%;
  }
}

.products {
  // padding-left: 25px;
  // padding-right: 25px;

  position: relative;
}

.device {
  position: relative;
  padding-top: 150px;

  // padding-right: 25px;
  // padding-left: 25px;
  .eyebrow,
  .heading-2 {
    text-align: right;

    @media (min-width: $min-width) {
    }
  }

  .card {
    .eyebrow,
    .heading-2 {
      text-align: left;
    }

    @media (min-width: $big-screen) {
      height: 670px;
    }
  }
}

.services {
  // padding: 0 40px;
  margin-top: 120px;

  @media (min-width: $min-width) {
    margin-top: 200px;
  }

  .service-content {
    justify-content: space-between;

    @media (min-width: $min-width) {
      display: flex;
      justify-content: space-between;
    }

    .accordion-img {
      display: block;

      .class-1 {
        display: flex !important;
        margin: unset !important;
        
        .image-container {
          position: relative;
          left: -6.5%;
          height: 30vh !important;
          width: 30vh !important;
          filter: blur(50px);
          background: radial-gradient(circle at 100% 100%, #07e3b0, #05eefa);
          -webkit-backdrop-filter: blur(50px) brightness(-40%) opacity(9%)
            saturate(10%);
          backdrop-filter: blur(100px) brightness(-40%) opacity(9%)
            saturate(10%);
          border-radius: 50%;
          z-index: 0;
        }
      }

      svg {
        z-index: 1;
        height: 56vh !important;
      }
    }
  }
}


.areasOfExpertise {
  background-color: $shade-black;
  margin: 100px 0px;
  padding: 30px 25px;
  justify-content: space-between;

  @media (min-width: $min-width) {
    margin: 200px 0px;
    display: flex;
  }

  .expertise-card {
    flex-direction: column;
    .title{
      font-size: 1.6rem !important;
      @media (min-width: $big-screen) {
        font-size: 2rem !important;
      }
    }
    .card-icon {
      padding-right: unset;
      margin-bottom: 1rem;

      .expertise-image {
        font-weight: bold;
        left: 17px;
        top: 13px;
        @media (min-width: $big-screen) {
          left: 16px;
          top: 7px;
          // width: 40%;
        }
      }

      @media (min-width: $big-screen) {
        padding-right: 10px;
      }
    }

    @media (min-width: $big-screen) {
      flex-direction: row;
    }
  }

  .header {
    .heading-2 {
      font-size: 2.5rem;
      line-height: 3.5rem;

      @media (min-width: $big-screen) {
        font-size: 3.5rem;
        line-height: 4.5rem;
      }
    }

    @media (min-width: $min-width) {
      width: 40%;
    }
  }

  .expertise-card-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @media (min-width: $min-width) {
      // width: 60%;
    }
  }

  .expertise-card {
    margin-top: 24px;
    display: flex;
    align-items: center;
    padding: 8px;
    width: 270px;

    @media (min-width: $min-width) {
      width: 280px;
      margin-left: 30px;
    }

    @media (min-width: $big-screen) {
      align-items: flex-start;
    }

    .card-icon {
      // padding-right: 40px;
      position: relative;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      height: 100%;

      .icon {
        width: 45px;
      }

      .expertise-image {
        // width: 25%;
      }
    }

    .text {
      font-size: 12px;
      text-align: justify;
      color: $white;

      @media (min-width: $big-screen) {
        font-size: 16px;
      }
    }

    .card-text {
      padding-left: 30px;
    }

    .icon {
      width: 75px;
    }

    .title {
      color: $white;
      text-align: center;
      font-size: 1.5rem;
      font-family: "IBM-Regular";

      @media (min-width: $min-width) {
        font-size: 2.5rem;
      }

      @media (min-width: $big-screen) {
        text-align: left;
      }
    }

    .exp-text {
      text-align: center;
      font-size: 1.4rem;
      line-height: 2rem;
      margin-top: 1rem;

      @media (min-width: $big-screen) {
        text-align: left;
      }
    }

    .expertise-image {
      width: 30%;
      position: absolute;
      left: 12px;
      top: 12px;

      @media (min-width: $min-width) {
      }
    }
  }
}

.capability-wrap{
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
  .box{
    width: 100px;
    margin: 1rem;
    .imgsec{
      width: 100%;
      height: 100px;
      background-color: $shade-black;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .title{
      text-align: center;
      color: $white;
      font-size: 1.6rem;
      margin-top: .5rem;
      padding: 0 .2rem;
    }
  }
}

.process-component {
  position: relative;
  margin-bottom: 100px;

  .heading-2 {
    padding-bottom: 30px;
  }

  // padding: 0 40px;
  .process {
    text-align: center;
    .deskview-arrows {
      .controls {
        // justify-content: flex-start !important;
        margin: 15px;
      }
      @media (min-width: $min-width) {
        display: none;
      }
    }
    .controls {
      display: flex;
      // justify-content: flex-start;
      margin: 15px;
    }

    &-content {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      margin-top: 40px;
      color: $white;
      position: relative;

      @media (min-width: $min-width) {
        flex-direction: row;
      }

      .process-text {
        display: flex;
        margin-top: -10px;
        border-radius: 10px;
        background: $shade-black 0% 0% no-repeat padding-box;
        text-align: left;
        padding: 33px 20px;
        font-family: "IBM-Regular";
        position: relative;
        z-index: 2;
        margin: 0 15px;

        @media (min-width: $min-width) {
          position: relative;
          // left: 50px;
        }

        &-content {
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          @media (min-width: $min-width) {
            height: 200px;
          }
        }

        &-heading {
          font-size: 20px;
          // line-height: 24px;
        }

        &-link {
          font-size: 1rem;
          line-height: 44px;
          opacity: 0.72;
          display: flex;
          // position: absolute;
          bottom: 0;

          @media (min-width: $min-width) {
            font-size: 2rem;
          }
        }

        .arrow {
          margin-left: 12px;
        }

        .arrow2 {
          margin-right: 14px;
        }
      }

      .process-video {
        width: calc(100% - 55px);
        border-radius: 10px;
        height: 180px;
        z-index: 1;
        @media (min-width: $min-width) {
          height: 500px;
          left: -75px;
        }
        video {
          object-fit: cover;
        }
        @media (min-width: $big-screen) {
          height: 500px;
        }
      }

      .process-text-description {
        margin-bottom: 10px;
        @media (min-width: $big-screen) {
          margin-top: 8px;
          font-size: 1.3rem;
          line-height: 2rem;
        }
      }
      .process-text-link {
        @media (min-width: $big-screen) {
          font-size: 1.6rem;
        }
      }
    }
  }

  .scale {
    padding-top: 25px;
  }
  

  .placeholder2 {
    width: 30px;
    height: 30px;
    background-color: red;
    position: absolute;
    top: 42px;
    left: 36px;
  }
}
.process-content {
  .controls-wrap {
    display: none;
    @media (min-width: $min-width) {
      display: flex;
    }
  }
}
.grid-8 {
  position: relative;
  &-bg-img {
    position: absolute;
    left: -206px;
    width: 40%;
    top: 60%;
    z-index: 0;
    display: none;
    @media (min-width: $min-width) {
      display: block;
      height: 40%;
    }
    @media (min-width: $big-screen) {
      height: unset;
    }
  }
}

.grid-4 {
  // overflow: hidden;
  position: relative;

  &-desktop {
    display: block;
  }

  &-bg-img {
    position: absolute;
    top: -101px;
    object-fit: cover;
    width: 56%;
    right: -103px;
    opacity: 0.6;

    @media (min-width: $big-screen) {
      right: -150px;
      top: 0px;
      width: 40%;
    }
  }
}

.grid-5 {
  // overflow: hidden;
  position: relative;

  &-desktop {
    display: block;
  }

  &-bg-img {
    position: absolute;
    top: 170px;
    object-fit: cover;
    left: -77px;
    width: 50%;
    opacity: 0.6;

    @media (min-width: $min-width) {
      top: 15%;
      object-fit: cover;
      left: -150px;
      width: 40%;
    }
  }
}

.grid-7 {
  &-bg-img {
    display: none;
  }
}

.about {
  // padding: 20px;
  display: flex;
  justify-content: end;

  &-box {
    border-radius: 10px;
    // background-color: rgba(54, 93, 62, 0.34);
    // -webkit-backdrop-filter: blur(13px);
    backdrop-filter: blur(8px) brightness(200%);
    border-radius: 10px;
    z-index: 1;
    @media (min-width: $min-width) {
      margin: 0;
      width: 60%;
    }

    .heading-2 {
      padding-bottom: 21px;
    }
  }

  &-content {
    padding: 20px;

    @media (min-width: $min-width) {
      padding: 40px;
    }
  }

  &-text {
    font-family: "IBM-Regular";
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.72px;
    color: $white;
    opacity: 0.7;
  }

  &_footer {
    color: $green-blue;
    font-family: "IBM-Bold";
    margin-top: 35px;
    cursor: pointer;
  }
}

.about-us {
  // background-image: url("../images/asdasdasd.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin-bottom: 7rem;
  .about-text {
    font-size: 1rem;

    @media (min-width: $big-screen) {
      font-size: 1.5rem;
    }
  }
}
.mt-mob60rem-d0rem{
  margin-top: 60rem ;
  @media (min-width: $big-screen) {
    margin-top: unset;
  }
}
.gradient-background {
  overflow: hidden;
  width: 100%;
  position: relative;
  background: transparent linear-gradient(0deg, #003d40 0%, $black 100%) 0% 0%
    no-repeat padding-box;
    opacity: .5;
  // margin: 3rem 0;
  .swiper-button-next{
    opacity: .5;
  }
  img {
    width: 100%;
    position: absolute;
    object-fit: unset;
    height: auto;
    top: 25%;
  }

  @media (min-width: $min-width) {
    top: 0;
  }
}

.video-component {
  position: relative;
  height: 100%;
  opacity: 0.6;
  // border: 1px solid $carbon-grey;

  .polygon {
    position: absolute;
    top: 42%;
    left: 45%;
    width: 50px;
    transition: transform 0.5s, fill 1s;
    transform-origin: 50% 50%;
  }

  &:hover {
    opacity: 1;

    // play button spin
    .polygon {
      transform: translateZ(0px) rotate(120deg);
    }
  }

  .play {
    position: absolute;
  }

  .video-description {
    text-align: right;
    position: absolute;
    bottom: 15px;
    right: 15px;
    .video-desc-text {
      font-size: 2rem !important;
      margin-bottom: -1rem;
    }
    .video-desc-logo {
      font-size: 3.8rem;
    }
  }
}

.cards-mobile {
  .swiper-slide {
    // max-width: 93% !important;
  }
}

.swiper-button-next,
.swiper-button-prev {
  color: $secondary !important;
}
.hero-text-wrapper {
  .controls {
    justify-content: center;
    @media (min-width: $big-screen) {
      justify-content: flex-start;
    }
  }
}
.grid7-5 {
  position: relative;
  .grid-7-bg-img {
    display: none;
    @media (min-width: $min-width) {
      display: block;
    }
    right: -150px;
    position: absolute;
    top: -150px;
    width: 40%;
  }
  .areasOfExpertise {
    position: relative;
    z-index: 2;
    min-height: 50rem;
    .expertise-card-wrapper{
      .row{
        display: flex;
        flex-wrap: wrap;

      }
    }
  }
}
.fullWidthHeight{
  width: 100%;
  height: 100%;
}
.navLogo{
  width: 145px !important;
  margin-top: 0 !important;
}