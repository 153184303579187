.cards {
  display: none;
  .card {
    z-index: 5;
    width: 95%;
    background: $shade-black;
    border-radius: 5px;
    padding: 20px;
    font-family: "IBM-Regular";
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 4rem;
    @media (max-width: 770px) {
      height: 100%;
      
    }
    
    @media (min-width: $min-width) {
      // height: 100%;
      width: 31%;
    }
    @media (min-width: $big-screen) {
      border-radius: 10px;
      height: unset;
    }
    .card-title {
      
      @media (min-width: $min-width) {
        font-size: 2.5rem;
        padding-bottom: 0.7rem;
      }
      // @media (min-width: $big-screen) {
      //   font-size: 2.5rem;
      // }
    }
    .card-description {
      font-size: 1.6rem;
      color: $white;
      opacity: 0.7;
      margin-top: 1rem;
      line-height: 2rem;
    }
    .eye-icon {
      direction: ltr;
      margin-bottom: 1rem;
      img {
        height: 47px;
        width: 47px;
      }
    }
    .card-link-text {
      color: $white;
    }
    &-title {
      color: $white;
      text-align: left;
      line-height: 41px;
    }
    &-description {
      text-align: left;
      color: $white;
      opacity: 0.7;
      padding-bottom: 20px;
    }
    &-link-text {
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;
      direction: ltr;

      .arrow {
        margin-left: 20px;
        width: 20px;
        height: 18px;
      }
    }
    &:hover {
      background: -webkit-linear-gradient($primary, $secondary);
      .card-description,
      .card-link-text {
        opacity: 1;
      }
    }
  }
}
.swiper-slide {
  max-width: 75% !important;
  .card-description {
    opacity: 70%;
    line-height: unset;
  }
}
.cards-mobile {
  display: flex;
}
.cards-desktop {
  display: none;
}

@media (min-width: $min-width) {
  .cards-mobile {
    display: none !important;
  }
  .cards-desktop {
    display: unset;
  }
  .cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    .card {
      margin: 10px;
    }
  }
}
