@font-face {
  font-family: IBM-Regular;
  src: url("../Fonts/IBMPlexSans-Regular.ttf");
}
@font-face {
  font-family: IBM-Bold;
  src: url("../Fonts/IBMPlexSans-Bold.ttf");
}

@font-face {
  font-family: IBM-Light;
  src: url("../Fonts/IBMPlexSans-Light.ttf");
}
@font-face {
  font-family: IBM-Medium;
  src: url("../Fonts/IBMPlexSans-Medium.ttf");
}

body {
  font-size: 16px;
  @media (min-width: $min-width) {
    font-size: 18px;
  }
  @media (min-width: $big-screen) {
    font-size: 24px;
  }
}
.aqua-color {
  color: $secondary !important;
}
.light-grey-color {
  color: $light-grey;
}
.heading-1,
Heading-1 {
  color: $white;
  font-size: 2rem;
  margin-bottom: 16px;
  font-family: "IBM-Light";
  @media (min-width: $min-width) {
    // font-size: 2.5rem;
    // text-align: left;
  }
  @media (min-width: $big-screen) {
    font-size: 6rem;
    text-align: left;
  }
}
.app-con{
overflow: hidden;
}
.heading-2,
Heading-2 {
  font-size: 2.5rem;
  line-height: 35px;
  color: $white;
  margin: 0;
  font-family: "IBM-Regular";
  padding-bottom: 1.2rem;
  // @media (min-width: $min-width) {
  //   font-size: 32px;
  //   line-height: 3rem;
  //   padding-bottom: 50px;
  // }
  @media (min-width: $min-width) {
    font-size: 4rem;
    line-height: 4.5rem;
  }
}
h5 {
  font-size: 1.4rem;
  font-family: "IBM-Regular";
  line-height: 2rem;
  margin-bottom: 20px;
  font-weight: normal;
  @media (min-width: $big-screen) {
    font-size: 2rem;
    line-height: 2.8rem;
  }
}
p {
  font-size: 1rem;
  font-family: "IBM-Regular";
  line-height: 1.4rem;
  margin-bottom: 20px;
  // @media (min-width: $min-width) {
  //   font-size: 0.8rem;
  //   line-height: 2rem;
  // }
  @media (min-width: $big-screen) {
    font-size: 2.8rem;
    line-height: 2.8rem;
  }
}
.text {
  font-size: 12px;
  font-family: "IBM-Regular";
  line-height: 16px;
  opacity: 0.7;
  @media (min-width: $min-width) {
    font-size: 16px;
    line-height: 24px;
  }
  @media (min-width: $big-screen) {
    font-size: 24px;
    line-height: 32px;
  }
}
.opacity {
  color: $white;
  opacity: 0.7;
}
.w100{
  width: 100%;
}
.mg-0-auto{
  margin: 0 auto;
}
.p-rel{
  position: relative;
}
.p-abs{
  position: absolute;
}
.w-25perc{

  width: 75%;
  @media (min-width: $min-width) {
    width: 25%;
  }
}