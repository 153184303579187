.services {
  @media (min-width: $min-width) {
    .heading-2 {
      padding-bottom: 45px;
    }
  }
}
.accordion-div {
  @media (min-width: $min-width) {
  }
}
.accordion-wrapper {
  & + * {
    margin-top: 0.5em;
  }
}

.accordion-div {
  flex: 1;
  height: 500px;
}
.accordion-item {
  overflow: hidden;
  transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
  height: auto;
  max-height: 9999px;
}
.accordian-button {
  padding: 30px 0;
}
.accordion-icon {
  display: flex;
  float: right;
  margin-left: auto;
  margin-right: 15px;
  svg {
    width: 22px;
  }
}
.accordion-item.collapsed {
  max-height: 0;
  transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
}

.accordion-title {
  cursor: pointer;
  color: $white;
  padding: 5px;
  background-color: $shade-black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // height: 30px;
  font-size: 14px;
  line-height: 30px;
  font-family: "IBM-Regular";
  @media (min-width: $min-width) {
    font-size: 16px;
  }

  &::after {
    content: "";
    width: 0;
    height: 0;
  }

  &:hover,
  &.open {
    color: $white;
  }

  &.open {
    &::after {
      content: "";
    }
  }
}
.accordion-border {
  // @include gradient;
  background: linear-gradient(90deg, $green-blue, $primary);
  height: 5px;
}
.accordion-content {
  padding: 5px;
  font-size: 1em;
  line-height: 30px;
  opacity: 0.7;
  font-family: "IBM-Regular";
  color: $white;
  @media (min-width: $min-width) {
    font-size: 16px;
  }
}
.accordion-img {
  display: none;
  @media (max-width:1000px) {
    display: none !important;
  }
  @media (min-width: $min-width) {
    // display: block;
    flex: 1;
    display: flex;
    align-items: center;
    position: relative;
    margin-left: 30px;
    justify-content: center;
    top: -40px;
    left: 20px;
    .background-image {
      position: absolute;
      width: 100%;
    }
    .foreground-image {
      position: absolute;
      width: 80%;
    }
  }
  .iconActive {
    transform: translate(-504px, -25px) scale(0.5);
    // transform: translate(-1045px, 10px);
  }
  #Path_97901,
  .icon1,
  .icon2,
  .icon3,
  .icon4,
  .icon5,
  #Group_135 {
    transition: all 0.3s ease-in-out;
    transform-box: fill-box;
    transform-origin: 50% 50%;
    cursor: pointer;
    &:hover {
      transform: scale(1.1);
    }
  }
  @keyframes rotateCircle {
    0% {
      // transform-origin: 90% 70%;
      transform: rotate(0deg) translate(1251.25px, 553.129px);
    }
    100% {
      // transform-origin: 90% 70%;
      transform: rotate(360deg) translate(1251.25px, 553.129px);
    }
  }
}
