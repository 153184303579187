.product-page {
  width: 100%;
  background-color: $black;
  color: $white;
  padding-top: 25px;
  display: flex;
  flex-direction: column;
  // align-items: center;
  overflow-x: hidden;
  margin-bottom: 10rem;
  @media (min-width: $big-screen) {
    overflow-x: unset;
  }
  .homepage-header {
    padding: 0;
    width: calc(100% - 80px);
  }
  .product-hero {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    @media (max-width:1000px) {
      width: unset;
    }

    .product-hero_container{
      width: 100%;
      .product-hero_right-content{
        display: flex;
        img{
          padding-right: 0px;
          @media (min-width: $big-screen) {
            // padding-right: 40px;
          }
        }
      }
    }
    .pd-land-img{
      height: 25vh !important;
      @media (min-width: $big-screen) {
        height: 45vh !important;
      }
    }
    // .heading-1 {
    //   @media (min-width: $min-width) {
    //     font-size: 90px;
    //     br {
    //       display: none;
    //     }
    //   }
    // }

    @media (min-width: $min-width) {
      align-items: flex-start;
    }
    .grid-1 {
      position: absolute;
      top: 760px;
      left: 280px;
      display: none;
      @media (min-width: $min-width) {
        display: block;
        top: 470px;
        left: -173px;
        width: 626px;
        height: 591px;
        opacity: 0.5;
      }
    }
    .grid-2 {
      display: none;
      @media (min-width: $min-width) {
        display: block;
        position: absolute;
        top: -240px;
        right: -200px;
        height: 590px;
        opacity: 0.5;
      }
    }
    // .grid-2-img
    @media (min-width: $min-width) {
      // align-items: unset;
      .heading-2 {
        br {
          display: none;
        }
      }
      .about-section {
        margin-top: 324px;
        width: 100%;
        // margin-bottom: 5rem;
      }

      .about {
        // margin-top: 324px;
        justify-content: end;
        // margin-bottom: 5rem;
        .heading-2 {
          text-align: left;
          width: 225px;
        }
      }
    }
    .about_footer {
      color: $green-blue;
      font-family: "IBM-Bold";
      margin-top: 35px;
      cursor: pointer;
      @media (min-width: $min-width) {
        top: 3852px;
        left: 974px;
        width: 116px;
        height: 13px;
        opacity: 1;
        // margin-top: 54px;
      }
    }
    &_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      @media (min-width: $big-screen) {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        height: calc(100vh - 112px);
        justify-content: space-evenly;
      }
    }
    &_left-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      @media (min-width: $big-screen) {
        display: unset;
        width: 50%;
        align-items: unset;
      }
    }
    &_right-content {
      @media (min-width: $min-width) {
        width: 50%;
      }
    }
    // &_image-container {
    //   display: flex;
    //   justify-content: center;
    //   position: relative;
    //   .background-image {
    //     width: 70%;
    //   }
    //   .foreground-image {
    //     width: 70%;
    //     position: absolute;
    //   }
    //   @media (min-width: $min-width) {
    //     margin-left: 250px;
    //     width: 50%;
    //     height: 100%;
    //     position: relative;
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     .background-image {
    //       position: absolute;
    //       width: 100%;
    //     }
    //     .foreground-image {
    //       height: 350px;
    //       width: 100%;
    //     }
    //   }
    // }
    &_about-section {
      text-align: center;
      img {
        width: 100%;
      }
      @media (min-width: $big-screen) {
        padding-right: 40px;
        display: flex;
        align-items: center;
        z-index: 1;
        padding-bottom: 70px;
      }
    }
    &-buttons {
      padding-bottom: 20px;
      display: flex;
      flex-direction: column;
      width: fit-content;
      align-items: center;
      @media (min-width: $min-width) {
        flex-direction: unset;
      }
    }
    // .heading-1 {
    //   margin: 20px auto !important;
    //   font-size: 30px;
    // }
    // .heading-2 {
    //   text-align: center;
    // }
    .secondary-button + .heading-2 {
      padding-top: 100px;
    }
    &-text,
    .product_about-text {
      font-family: "IBM-Regular";
      color: $light-grey;
      padding: 23px 0px 50px;
      text-align: center;
      @media (min-width: $big-screen) {
        padding: 0;
        padding-top: 60px;
        padding-bottom: 50px;
        text-align: left;
      }
    }
    .left-align {
      @media (min-width: $min-width) {
        position: relative;
        left: 35px;
        margin-top: 148px;
      }
      .heading-2 {
        text-align: unset;
      }
    }
    .product-feature_list {
      display: flex;
      flex-direction: column;
      @media (min-width: $big-screen) {
        
      }
      @media (min-width: $min-width) {
        flex-direction: row;
        z-index: 1;
      }
    }
    .product-feature {
      width: 100%;
      z-index: 2;
      @media (min-width: $min-width) {
        width: 355 px;
      }
      background: rgb(0, 0, 0);
      background: transparent
        linear-gradient(180deg, $aqua-deep 0%, $bluish-cyan 100%) 0% 0%
        no-repeat padding-box;
      padding-bottom: 30px;
      border-radius: 10px;
      margin-right: 16px;
      margin-bottom: 20px;
      &_content {
        padding: 28px;
        .feature_heading {
          font-size: 20px;
          font-family: "IBM-Regular";
          padding-bottom: 20px;
          @media (min-width: $big-screen) {
            font-size: 24px;
          }
        }
        .feature_text {
          font-family: "IBM-Regular";
          opacity: 0.7;
          @media (min-width: $big-screen) {
            font-size: 2rem;
          }
        }
      }
    }

    img {
      @media (min-width: $min-width) {
        width: 350px;
        padding-right: 40px;
      }
      @media (min-width: $big-screen) {
        width: 400px;
      }
    }
  }

  .wave-1 {
    position: absolute;
    @media (min-width: $min-width) {
      display: block;
      position: absolute;
      top: 2250px;
      width: 1300px;
      background: transparent 0% 0% no-repeat padding-box;
      opacity: 0.5;
    }
  }
  .blue-wave {
    overflow: hidden;
    width: 100%;
    position: relative;
    height: 100%;
    &-desktop {
      display: none;
    }
    &-mob {
      display: block;
    }
    img {
      width: auto;
      position: absolute;
      height: inherit;
      // object-fit: cover;
    }
  }
  @media (min-width: $min-width) {
    .about-box {
      min-height: 575px;
    }
    .blue-wave {
      margin-top: 80px;
      overflow: hidden;
      width: 100%;
      position: relative;
      height: 100%;
      &-desktop {
        display: none;
      }
      &-mob {
        display: block;
      }
      img {
        width: 100%;
        position: absolute;
        height: inherit;
      }
    }
  }
}
.about-section {
  margin-top: 100px;
  margin-bottom: 5rem;
  background-image: none;
  
  background-repeat: no-repeat;
  background-size: cover;
  @media (min-width: $big-screen) {
    background-size: contain;
  }
  @media (min-width: $min-width) {
    // background-image: url("../images/asdasdasd.png");
  }
}

.reset-p{
  padding: 0;
}
.about{
  position: relative;
  .about-content-wrap{
    display: flex;
    justify-content: flex-end;
  }
  .waweImg{
    width: 193%;
    position: absolute;
    right: -60px;
    top: 107px;
    height: 50%;
    @media (min-width:$min-width) {
      
    }
    @media (min-width: $min-width) {
      width: 100%;
      position: absolute;
      right: 0;
      top: -80px;
      height: 130%;
    
    }
  }
}
