$aqua-deep: #003d40;
$primary: #07e3b0;
$black: #000000;
$bluish-cyan: #081e21;
$secondary: #05eefa;
$grey: #a3a3a3;
$light-grey: #b3b3b3;
$carbon-grey: #5f5f5f;
$cloudy-grey: #646464;
$shade-black: #1b1b1b;
$green-blue: #4faeb8;
$granite: #808080;
$ironside-grey: #666666;
$midnight-grey: #00000029;
$white: #ffffff;
$star-dust: #9b9b9b;
$min-width: 769px;
$big-screen: 1366px;
$header-height: 90px;
