@import "../Styles/mixins.scss";
.buttons {
  margin-top: 40px;
  // display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: $min-width) {
    flex-direction: row;
    justify-content: space-around;
  }
}
.button {
  font-size: 14px;
  text-transform: uppercase;
  padding: 0.75em 1.75em;
  cursor: pointer;
  font-family: "IBM-Bold";
}
.primary-button {
  position: relative;
  border-radius: 50px;
  z-index: 2;
  border: none;
  color: $white;
  background: $shade-black;
  transition: (all 0.6s);
  margin-bottom: 40px;
  font-size: 1.2rem;
  @media (min-width: $big-screen) {
    margin-left: 15px;
  }
  @media (min-width: $big-screen) {
    margin-bottom: 0;
    margin-right: 40px;
    font-size: 1.4rem;
  }

  &:before {
    content: "";
    z-index: -1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    @include gradient;
    transform: translate3d(0px, 0px, 0) scale(1.07);
    filter: blur(10px);
    opacity: var(0.7);
    transition: opacity 0.3s;
    border-radius: inherit;
  }

  /* 
* Prevents issues when the parent creates a 
* stacking context. (For example, using the transform
* property )
*/
  &::after {
    content: "";
    z-index: -1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: inherit;
    border-radius: inherit;
  }
  &:hover {
    background: $primary;
  }
}

.secondary-button {
  border: none;
  background: $black;
  background: -webkit-linear-gradient($primary, $secondary);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.primary-button:disabled,
.secondary-button:disabled {
  color: $cloudy-grey;
  &:hover {
    box-shadow: none;
    background-color: $shade-black;
  }
}
