@mixin gradient {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from($primary),
    to($secondary)
  );
  background: -webkit-linear-gradient(left, $primary, $secondary);
  background: -o-linear-gradient(left, $primary, $secondary);
  background: linear-gradient(to right, $primary, $secondary);
}

@mixin blur($radius) {
  -webkit-filter: $radius;
  -moz-filter: $radius;
  -o-filter: $radius;
  -ms-filter: $radius;
  filter: $radius;
}

@mixin transition($properties) {
  -webkit-transition: $properties;
  -o-transition: $properties;
  transition: $properties;
}
