.scale {
  display: none;
  align-items: center;
  justify-content: center;
  padding-bottom: 68px;
  @media (min-width: $big-screen) {
    display: flex;
  }
  .scale-item {
    font-size: 18px;
    margin-left: 27px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "IBM-Regular";
    opacity: 0.5;
    .gradient {
      width: 5px;
      height: 25px;
      &-up {
        background-image: linear-gradient(0deg, $granite 0%, $black 100%);
        margin-bottom: 10px;
      }
      &-down {
        background-image: linear-gradient(0deg, $black 0%, $granite 100%);
        margin-top: 10px;
      }
    }
    &.active {
      transition: 0.3s;
      font-size: 24px;
      opacity: 0.8;
      .gradient {
        height: 25px;
      }
      .gradient-up {
        background-image: linear-gradient(0deg, $aqua-deep 0%, $black 100%);
      }
      .gradient-down {
        background-image: linear-gradient(0deg, $black 0%, $aqua-deep 100%);
      }
    }
  }
}
